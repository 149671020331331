import React, {PureComponent} from 'react';
import Main from './container';

class App extends PureComponent {
	render() {
		return (
			<Main />
		);
	}
}

export default App;
